/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "criswellofficial-adabandenv",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1hkwascgr2smv.cloudfront.net",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_8Zxzk4CM2",
    "aws_user_pools_web_client_id": "5cf380b4t9q8es84bcnd9igmqd",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
