<template>
  <div style="text-align:center">
    <h1 style="text-align: center">OneBand Software</h1>
    <h3 style="text-align: center">Coming Soon</h3>
    <br>
    <button onclick="location.href = 'https://app.onebandsoftware.com'" class="btn btn-1" style="width: 50%">Sign In</button>
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
            return {
               
            }
        },
  created() {
      document.body.style.backgroundColor = "lightgrey"
  },
  methods: {
    
  },
  
};
</script>

<style>
.btn-1 {
  background-color: #7500FF;
  color: white;
}
.btn-1:hover, .btn-1:active {
  filter: brightness(0.85);
  color: white;
  outline: none !important;
  box-shadow: none !important;
}

.btn-2 {
   background-color: dodgerblue;
   color: white;
} 

.btn-2:hover, .btn-2:active {
  filter: brightness(0.85);
  color: white;
  outline: none !important;
  box-shadow: none !important;
}
</style>